.equipment-grid {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  @media #{$small-screen} {
    padding:0;
  }
}

.equip-card {
  min-height: 415px;
  text-align: center;
  border: solid 1px #999;
  border-radius: 5px;
  width: 32%;
  float: left;
  padding: 10px;
  margin: 0 1% 10px 1%;
  &:nth-child(3n+1){
    margin-right: 0;
  }
  &:nth-child(3n + 3){
    margin-left:0;
  }

  @media #{$xmedium-screen} {
    width: 48%;

    &:nth-child(3n+1){
      margin-right: 1%;
    }
    &:nth-child(3n + 3){
      margin-left:1%;
    }
  }
  @media #{$xssmall-screen} {
    width:100%;
  }
}

.equip-image img {
  max-width: 100%;
  padding: 20px;
}

.equip-info {
  h4 {
  margin: 0;
  }
  p {
    margin-top: 5px;
    font-weight: normal;
  }
}
