.registration {
  padding-bottom: 40px;
  @media #{$small-screen} {
    padding-bottom: 0;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: center;
    max-width: 300px;
    display: block;
    margin: 0 auto;
    padding-top: 20px;
  }
  h2 {
    text-align: center;
    padding-top: 20px;
  }
  .btn, button {
    width: 50%;
    margin: 0 auto;
    display: block;
  }
  .login,.register{
    width:50%;
    float:left;
    @media #{$small-screen} {
      width:100%;
    }
  }
  .login{
    padding-right: 40px;
    @media #{$small-screen} {
      padding-right: 0px;
      margin: 30px 0;
    }
  }
  .register{
    padding-left: 40px;
    position: relative;
    &::before {
      content: '';
      background-color: #ebebeb;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 19px;
      @media #{$small-screen} {
        display: none;
      }
    }
    @media #{$small-screen} {
      padding-left: 0px;
    }
  }
}
.registration-filter{
  $root:&;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  &__image,&__or{
    width:33%;
    @media #{$small-screen} {
      width:100%;
    }
  }
  &__image{
    padding:20px 0;
  }
  &__or {
    text-align: center;
    span{
      display: inline-block;
      font-size: 1.2rem;
      border: solid 1px #d2d2d2;
      border-radius: 100px;
      padding: 20px;
      @media #{$xssmall-screen} {
        font-size: 1rem;
        padding: 10px;
      }
    }
  }
  a, h3 {
    text-decoration: none;
    font-size: 1.7rem;
    @media #{$xssmall-screen} {
      font-size: 1.2rem;
    }
  }
  h3:hover {
    color: color('carusos-red','lighten-1');
  }
}
