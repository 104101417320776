.pagination,.pager{
  list-style: none;
  padding:0;
  display: block;
  text-align: center;

  li{
    border: 1px solid color("gray", "lighten-1");
    font-size: 2rem;
    display: inline-block;
    height:50px;
    width:50px;
    text-align: center;
    line-height: 50px;
    margin: 0 10px;

    &:first-child{
      margin: 0 10px 0 0;
    }
    &:active{

    }
    
    @media #{$xmedium-screen}{
      display: none;

      &.arrows{
        display: inline-block;
      }
    }
  }
}