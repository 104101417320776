.shop-category{
  padding:0 5px;
  &:first-child{
    padding-left: 0;
  }
  &:last-child{
    padding-right: 0;
  }
  .title{
    margin-bottom: 0;
    font-size: 1rem;
  }
  .brand{

  }
}
.shop .category{
  max-height:200px;
  overflow: hidden;
  margin-bottom: 20px;

  h2{
    width: 80%;
    @media #{$small-screen} {
      font-size:1.2rem;
    }
    @media #{$xssmall-screen} {
      font-size:2rem;
    }
  }
}
.shop-section{
  h2{
    
  }
}
.best-sellers{
  a{
    text-decoration: none;
  }
  .product-block{
    &:nth-child(odd){
      @media #{$small-screen} {
        border-right:1px solid #bdbdbd;
      }
    }
    &:nth-child(1), &:nth-child(2){
      @media #{$small-screen} {
        border-bottom:1px solid #bdbdbd;
      }
    }
  }
}