$gray: (
        "lighten-5":  #fafafa,
        "lighten-4":  #f5f5f5,
        "lighten-3":  #eeeeee,
        "lighten-2":  #e0e0e0,
        "lighten-1":  #bdbdbd,
        "base":       #9e9e9e,
        "darken-1":   #757575,
        "darken-2":   #616161,
        "darken-3":   #424242,
        "darken-4":   #212121
);

$blue: (
        "lighten-1" : #3E737b,
        "base" :#004d5a
);
$green: (
        "base" :#52990B
);

$red : (
        "base" : #AA320C
);

$shade:(
        "white": #FFFFFF,
        "black" : #000000
) !default;

$colors: (
        "shade": $shade,
        "blue": $blue,
        "green": $green,
        "gray" : $gray,
        "red" : $red,
) !default;

@if(variable_exists("custom-colors")){
  $colors: map-merge($colors,$custom-colors);
}

@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text {
        color: $color_value !important;
      }
    }
    @else {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);
    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }
  @warn "Unknown `#{name}` in $colors.";
  @return null;
}
