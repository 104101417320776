.no-padding{
  padding:0;
}

html{
  overflow-y: scroll;
}

hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid color("gray","lighten-1");
  margin: 0;
  padding: 0;
}
.hide{
  display:none !important;
}

.show{
  display:block !important;
}
body{
  background: color("shade","white");
}