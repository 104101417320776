.shop{
  h1,h2,h3,h4,h5,h6{
    text-transform: uppercase;
  }

  h2{
    color: color("carusos-red","base");
    @media #{$small-screen} {
      font-size:1.5rem;
    }
  }
}