.page-404{
  background: url("/img/carusos-404mug.jpg") no-repeat right top;
  min-height:600px;
  text-align: center;

  &__text-container{
    max-width: 600px;
  }
  h2,h3,p,a{
    color:color("gray","lighten-1");
    text-transform: uppercase;
  }
  h2{font-size:7rem;}
  h3{font-size:2.5rem;}
  p{}

  @media #{$large-screen} {
    &__text-container{
      max-width: 500px;
    }
    h2{
      font-size:5rem;
    }
    h3{
      font-size:2rem;
    }
  }

  @media #{$xmedium-screen} {
    &__text-container{
      max-width: 300px;
    }
  }

  @media #{$medium-screen} {
    background: none;
    min-height:0;
    &__text-container{
      max-width: none;
    }
  }
}