.hero{
  position: relative;
  z-index: 0;
  padding:0;

  .hero-content{
    display: flex;
    align-items:center;
    justify-content: center;
    padding-top: 10em;

    @media #{$small-screen} {
      padding-top: 0;
      height: 170px;
    }
  }
  .call-to-action{
    z-index:100;
    margin-top: 22px;
    .btn {
      margin-right: 10px;
      background-color: color("carusos-red","lighten-1");
      border-radius: 5px;
      &:hover {
        background-color: color("carusos-red","base")
      }
    }
    @media #{$small-screen} {
      display: none;
    }
  }

  &#home {
    height: 535px;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero4.jpg"); /* FF3.6-15 */
    background:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero4.jpg"); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero4.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-repeat: no-repeat;
    background-size: cover;

    &.version-1{
      background-image: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero3.jpg"); /* FF3.6-15 */
      background-image:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero3.jpg"); /* Chrome10-25,Safari5.1-6 */
      background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero3.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.version-2{
      background-image: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero4.jpg"); /* FF3.6-15 */
      background-image:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero4.jpg"); /* Chrome10-25,Safari5.1-6 */
      background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero4.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.version-3{
      background-image: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero5.jpg"); /* FF3.6-15 */
      background-image:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero5.jpg"); /* Chrome10-25,Safari5.1-6 */
      background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero5.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.version-4{
      background-image: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero6.jpg"); /* FF3.6-15 */
      background-image:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero6.jpg"); /* Chrome10-25,Safari5.1-6 */
      background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero6.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.version-5{
      background-image: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero7.jpg"); /* FF3.6-15 */
      background-image:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero7.jpg"); /* Chrome10-25,Safari5.1-6 */
      background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-homepage-hero7.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    @media #{$small-screen} {
      height: 170px;
      background-size: cover;
    }

    #stamp{
      padding-right: 0.75em;
      @media #{$medium-screen} {
        display: none;
      }
    }

    h1{
      color: color("gray","lighten-1");
      font-size: 4.5rem;
      line-height: 1em;
      padding: 0;
      margin: 0;

      span{
        font-size: 5.25rem;
        @media #{$medium-screen} {
          text-align: center;
          font-size: 3rem;
        }

        @media #{$small-screen} {
          text-align: center;
          font-size: 2rem;
        }
      }


      @media #{$medium-screen} {
        text-align: center;
        font-size: 3rem;
      }

      @media #{$small-screen} {
        text-align: center;
        font-size: 2rem;
      }
    }
  }
  .mask{
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height:535px;
    background: url("/img/dot-pattern-overlay.png");

    @media #{$small-screen} {
      height: 170px;
    }
  }
}

/*--SHOP STYLES--*/


.shop-hero{
  position: relative;
  z-index: 0;
  padding:0;

  .hero-content {
    display: flex;
    align-items:center;
    justify-content: center;
    padding-top: 6em;

    @media #{$small-screen} {
      padding-top: 40px;
      height: 75px;
    }
      input {
        height: 30px;

      }
    }
    .hero-text {
      max-width: 80%;
    }

  .call-to-action{
    z-index:100;
    margin-top: 22px;

    @media #{$small-screen} {
      display: none;
    }
  }

  &#home {
    height: 355px;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%), url("/img/carusos-shop-hero4.jpg"); /* FF3.6-15 */
    background:  -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-shop-hero4.jpg"); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 100%), url("/img/carusos-shop-hero4.jpg"); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-size: cover;
    background-repeat: no-repeat;

    @media #{$small-screen} {
      height: 170px;
      background-size: cover;
    }

    #stamp{
      padding-right: 0.75em;
      @media #{$medium-screen} {
        display: none;
      }
    }

    h1{
      color: color("gray","lighten-1");
      font-size: 3.5rem;
      max-width: 70%;
      line-height: .9em;
      padding: 0;
      margin: 0 auto;

      @media #{$small-screen} {
        text-align: center;
        font-size: 2rem;
      }

      @media #{$xssmall-screen} {
        max-width: 80%;
        text-align: center;
        font-size: 1.25rem;
      }

      span{
        font-family: 'garamond',serif;
        text-transform: lowercase;
        font-size: 4rem;
        font-weight: lighter;
        font-style: italic;
        @media #{$medium-screen} {
          font-size: 2rem;
        }
        @media #{$small-screen} {
          font-size: 1.5rem;
        }
      }
    }
  }
  .mask {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    background: url("/img/dot-pattern-overlay.png");

    @media #{$small-screen} {
      height: 170px;
    }
  }
}
/*SHOP HERO END */


.internal-hero{
  min-height: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  width: 100%;
  h1,h2{
    color: color("shade","white");
    margin: 0;
    font-size: 3.5rem;

    @media #{$xmedium-screen} {
      font-size:3rem;
    }

    @media #{$small-screen} {
      font-size:1.5rem;
    }
  }
  @media #{$small-screen} {
    min-height: 113px;
  }

  a, p{
    color: color("shade","white");
    text-decoration: none;
    margin: 0;

    &:focus,&:hover,&:visited{
      color: color("shade","white");
      }
  }

  .container {
    margin-bottom: 40px;
    padding-left: 40px;
    position: relative;
    @media #{$small-screen} {
      text-align: center;
      margin-bottom: 20px;
      padding: 0;
    }
  }

  .right-aside{
    position: absolute;
    right:0;
    top:0;
    @media #{$medium-screen} {
      right:20px;
    }
  }
}
