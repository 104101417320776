.one-column-internal-content .container .wrapper.account-page {
  padding-top: 0;

  table.full {
    width:100%;
    text-align: left;
    border-bottom: solid 1px color('gray','lighten-1');
    padding-bottom: 30px;
    float: left;
    td {
      padding: 15px 0px;

    }
  }
  .order-history {
    width: 70%;
    margin-right: 20px;
    float: left;
  }
  .account-details {
    background-color: color('gray','lighten-4');
    float: left;
    width: 25%;
    padding: 0 20px;
  }


}

.one-column-internal-content .container .wrapper.add-addresses {
  padding-top: 0;
}

.one-column-internal-content .wrapper {
  .add-addresses {
    margin: 0px 0px 30px;
    padding: 30px 0;
    border-bottom: solid 1px color('gray','lighten-1');
    a:nth-child(2) {
      float: right;
      @media #{$xssmall-screen} {
        float: none;
        display: block;
        margin-top: 20px;
      }
    }
  }
  .addresses {

    p {
      margin-top: 0;
      a {
        &:last-child {
          position: relative;
          margin-left: 15px;
          &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 20px;
            background-color: color('gray','lighten-1');
            top: 1px;
            left: -8px;
          }
        }
      }
    }
  }
}
// Add collapsable address box styles

  .edit-addresses-box {
    background-color: color('gray','lighten-2');
    padding: 20px;
    border-radius: 5px;
    margin-bottom:20px;

    input[type='text'], input[type='select'] {
      display: inline-block;
      width: 300px;
      @media #{$xssmall-screen} {
        width: 100%;
      }
    }
    label {
      display: inline-block;
      width: 120px;
    }

  }


.shop .quick-access{
  @media #{$xssmall-screen} {

  }
}
