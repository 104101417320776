.posts{
  display: flex;
  justify-content: space-between;

  @media #{$xssmall-screen} {
    flex-direction: column;
  }
  .post{

    @media #{$xssmall-screen} {
      max-width: 100%;
      padding-bottom: 3em;
    }
  }
}
.item{
  padding:50px 0;
  &:first-child{
    padding-top: 0;
  }
  h2{
    font-size: 2rem;
    font-weight: bold;
    margin:0.5em 0;
    a{
      text-decoration: none;
    }
  }
  p{
    font-size:1rem;
  }

  .body-copy{
    a{
      color: color("carusos-red", "base");
      &:visited,&:hover, &:focus{
        color:color("carusos-red", "base");
      }
    }
  }

  ul.info{
    list-style:none;
    margin:0;
    padding:0 0 10px 0;
    li {
      display: inline-block;

      &:after{
        content: ' / ';
      }
      &:last-child{
        &:after{
          content: '';
        }
      }
    }
  }

}