/***
* Search Component Definition
***/
.search{
  margin:10px auto 0;
  position: relative;

  &:after {
    font-family: FontAwesome;
    content:'\f002';
    color: color("gray","lighten-1");
    position: absolute;
    top:18px;
    margin: auto 0;
    right:15px;
  }
}
.search{
  max-width: 300px;
  @media #{$small-screen} {
    max-width: none;
  }
}

/***
* Particular Page Layouts
***/
.two-column-left-content{
  .search-bar{
    display: none;
    @media #{$small-screen} {
      padding-top:70px;
      display: block;
    }
  }
}
.internal-hero{
  .search-bar{
    @media #{$small-screen} {
      display: none;
    }
  }
  .search{
    margin: 10px 0 0 auto;
  }
}
.one-column-internal-content{
  .search-bar{
    @media #{$medium-screen-and-up} {
      display: none;
    }
  }
}
.shop-hero{
  .search-bar{
    margin:0 auto;
  }
}