ul.pagination {
    li {
      border: none;
      margin: 0;
      width: 40px;
      &:first-child {
        width: inherit;
        margin: 0;
      }
      &:last-child {
        width: inherit;
      }

      a,span{
        font-size: 1rem;
        text-decoration: none;
        padding: 5px 10px;
      }

      @media #{$xmedium-screen} {
        display: inline-block;
      }
    }
  .disabled {
    a,span {
      color: color('gray','lighten-2');
    }
  }

  .active {
    a,span {
      color: color('shade','white');
      background-color: color('carusos-red','lighten-1');
    }
  }
}
