/**
* Filter Component Definition
**/
.left-nav ul.shop-filter {
  a.filter-label {
    padding: 10px !important;
    text-align: left;
    background-color: color('gray','lighten-1');
    color: color('shade','white');
    font-weight: bold;

    &:hover{
      background-color: color('gray','lighten-1');
    }
  }
  p {
    margin: 4px 0 4px 8px;
    a{
      padding: 5px;
      background: transparent;
      text-align: left;
      border-top: none;

      &:hover{
        background: transparent;
        color: color("gray","base");
      }

      &:before{
        content: '';
        background-color: color('shade','white');
        border: solid 1px color('gray','lighten-1');
        width: 16px;
        height: 16px;
        display: inline-block;
        margin: -2px 6px 0 0;
        line-height: 16px;
        vertical-align: middle;
        text-align: center;
      }
    }
    &.disabled{
      a{
        cursor: not-allowed;
        color: color("gray","lighten-1");
      }
    }
    &.active-filter{
      a:before{
        font-family:FontAwesome;
        color:color("carusos-red","lighten-1");
        content: '\f00c';
      }
    }
  }
}

.filter-controls{
  position: relative;
  width:100%;
  @media #{$medium-screen} {
    border-bottom:1px solid color("gray","lighten-2");
    padding-bottom: 25px;
  }
  @media #{$small-screen} {
    padding-bottom: 0;
  }
  .sorting{
    position: absolute;
    right:30px;
    min-width: 160px;
    @media #{$medium-screen} {
      position: static;
      float:right;
    }
  }
  .display-filter{
    a{
      display: block;
    }
    float:left;
    display: none;
    @media #{$medium-screen} {
      display: block;
    }
  }
}
/***
* Page Specific Layouts
***/
.shop .two-column-left-content{
  .left-nav{
    padding:0;
    ul li:first-child{
      a{
        border-radius:5px 5px 0 0;
      }
    }
  }
  @media #{$medium-screen} {
    .wrapper{
      display: block !important;
    }
    .left-nav{
      display: block;
      padding:0;
      img{
          display: none;
        }
    }
    .right-content{
      padding-top:0;
    }
  }

  @media #{$medium-screen} {
    .shop-filter{
      display: none;
    }
  }

  .display-filter{
    display: none;
    @media #{$medium-screen} {
      display: block;
    }
  }
}
