.icon{
  &.bars{
    position: relative;
    padding-left: 1.25em;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 4px;
      border-top: 10px double #FFF;
      border-bottom: 3px solid #FFF;
    }
  }
}

//Include Font awesome!!!
//Current Version 4.6.0
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.6.0/css/font-awesome.min.css");