a.btn, button{
  transition: all 0.3s;
  width:100%;
  border: 0;
  padding:10px;
  text-transform: uppercase;
  font-weight:600;
  @media #{$xssmall-screen}{
    width:100%;
    text-align: center;
  }

  &.block{
    display: block;
    box-sizing: border-box;

    &.center{
      margin: 0 auto;
    }
  }
  &.inline{
    width:auto;
    display: inline;
  }

  &:hover{
    cursor: pointer;
  }
  &:hover,&:focus,&:active,&:visited{
    outline: none;
  }
}

a.btn{
  text-decoration: none;
}