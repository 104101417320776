*{
  box-sizing: border-box;
}
.left{
  float:left !important;
}
.right{
  float:right !important;;
}

.small-pad-top{
  padding-top: 1rem;
}

.small-pad-bottom{
  padding-bottom: 1rem;
}

.small-pad-both{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.medium-pad-top{
  padding-top: 2rem;
}

.medium-pad-bottom{
  padding-bottom: 2rem;
}
.no-padding{
  padding:0 !important;
}
.no-margin{
  margin:0 !important;
}
.flex{
  display: flex;
  &.vert-center{
    align-items: center;
  }
  &.hori-center{
    justify-content: center;
  }


  &-wrap{
    flex-wrap: wrap;
  }

  &-vert-center{
    align-items: center;
  }
  &-hori-center{
    justify-content: center;
  }
  &-wrap-xs{
    @media #{$xssmall-screen} {
      flex-wrap: wrap;
    }
  }
  &-wrap-sm{
    @media #{$small-screen} {
      flex-wrap: wrap;
    }
  }
}

.center-middle{
  margin:auto;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.block{
  display: block;
  &.center{
    margin:  0 auto;
    &.constrain{
      max-width: 600px;
    }
  }
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

.img-responsive{
  max-width:100%;
  margin:0 auto;
}

@function reverse($list, $recursive: false) {
  $result: ();

  @for $i from length($list)*-1 through -1 {
    @if type-of(nth($list, abs($i))) == list and $recursive {
      $result: append($result, reverse(nth($list, abs($i)), $recursive));
    }

    @else {
      $result: append($result, nth($list, abs($i)));
    }
  }

  @return $result;
}


%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}