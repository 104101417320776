.cart-form{
  margin: 0 auto;
  @media #{$medium-screen-and-up} {
    width:90%;
  }
  &__items{
    width:100%;
  }
  &__item{
    border-top: 2px solid color("gray","lighten-2");
    padding: 20px 0;
    width:100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;

    &:first-child {
      border-top: none;
    }
    &__info{
      padding-left: 10px;
      max-width:260px;

      @media #{$small-screen}{
        padding:0;
        width: 100%;
      }
    }
    &__image{
      height: 100px;
      width: 100px;
      @media #{$small-screen}{
        height:auto;
        width: auto;
        max-width: 100%;
      }
    }
    &__title{
      text-decoration: none;
      color: color("carusos-red","lighten-1");
      font-size:1.25em;
      &:visited{
        color: color("carusos-red","lighten-1");
      }
    }

    &__pricing{
      text-align: center;
      @media #{$small-screen}{
        width: 100%;
      }
    }
    &__content{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      @media #{$small-screen}{
        width:100%;
        text-align: center;
      }
    }
  }
  &__totals{

    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    margin-top: 25px;
    padding: 10px 0;

    &__left {
      margin-right: 25px;
      border-right: 1px solid $light-gray;
      padding: 15px 25px 15px 0;


      tbody {
        text-align: right;
        tr {
          padding-bottom: 15px;
        }
      }
      .table--totals {
        color: color("carusos-red","base");
        font-weight: bold;
        font-size: 1.5rem;
        padding-left: 15px;
      }
    }
    &__right {

    }
  }
}

.cart-form__item__price,.cart-form__item__qty,.cart-form__item__total,.cart-form__item__actions{
  display: inline-block;
  padding:0 10px;

  @media #{$small-screen}{
    padding:0 5px;
  }

  &:first-child{
    padding-left:0;
  }

  &__label{
    color: color("gray","base");
  }
  span{
    padding: 0 0 5px 0;
    display: block;
  }
}

.ajaxifyCart--content{
  .cart-form__items{
    width:100% !important;
    float: none !important;
  }
  .cart-subtotal{
    width:100% !important;
    float: none !important;
  }
}


/*-----*/

.product-price {
  font-weight: bold;
  font-size: 1.2rem;
  color: color("carusos-red","base");
}

.product-block {
  padding-bottom: 40px;
}

.product-info {
  h2 {
    color: color("carusos-red","base");
    font-size: 2rem;

  }
}

span#productPrice {
  font-size: 2rem;
  color: color("carusos-red","base");
  font-weight: bold;
}
