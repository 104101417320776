section#newsletter{
  background-image: url("/img/footer-bg.jpg");
  background-repeat: no-repeat;
  color: color("shade","white");
  background-size: cover;

  .container{
    max-width: 800px;
    padding: 3em 0;

    @media #{$medium-screen} {
      padding: 1em;
    }
  }

  .heading{
    font-size:2rem;
    text-transform: uppercase;
    color: color("shade", "white");
    @media #{$medium-screen} {
      font-size:1.5rem;
    }
  }

  p{
    font-size:1.5rem;
    @media #{$medium-screen} {
      font-size:1.25rem;
    }

    @media #{$medium-screen} {
      padding: 1em 1em;
    }
  }
}
footer{
  padding: 2em 0;
  text-align: center;
  position: relative;

  .address{
    font-size: 0.9rem;
  }
  .brands{
    list-style: none;
    padding:0 0 1em 0;
    margin: 0;
    @media #{$xssmall-screen} {
      text-align: center;
    }

    li{
      display: inline-block;
      padding:0 12px;

      &:first-child{
        padding-left:0;
      }

      @media #{$small-screen} {
        padding:10px;
      }

      img {
        @media #{$small-screen} {
          height: 30px;
        }
      }

    }
  }

  .branding{
    text-align: right;
    font-size: 11px;

    @media #{$medium-screen} {
      text-align: center;
      margin-top: 20px;
    }
  }

  .links{
    font-weight: bold;
    font-size:0.85rem;
    list-style: none;
    padding:0 0 1em 0;
    text-align: right;

    @media #{$medium-screen} {
      display: none;
    }


    li{
      &:after{
        content: ' / ';
      }

      &:last-child:after{
        content: '';
      }

      display: inline-block;
      a{
        color: color("carusos-red", "lighten-1");
        text-transform: uppercase;
        text-decoration: none;
        &:hover{
          color: color("gray", "lighten-1");
        }
      }
    }
  }
  .version{
    color:rgba(0,0,0,0.1);
    position: absolute;
    left:0;
    bottom:0;
  }
}

.footer{
  &__social-icons{
    padding-right: 5px;
    i{
      padding-right: 5px;
      vertical-align: sub;
    }
  }
}