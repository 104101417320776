.account{
  max-width:50%;
  margin:0 auto 100px;
  @media #{$medium-screen}{
    margin:0 auto 50px;
    max-width:none;
  }

  &__header{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid color("gray","base");
    padding-bottom:5px;
    @media #{$small-screen} {
      margin-top: 25px;
    }
  }

  &__order{

    &__addresses,&__fulfillment,&__items,&__totals{
      padding:1em 0;
    }
    &__addresses,&__fulfillment,&__items{
      border-bottom: 1px solid color("gray","base");
    }
    &__addresses{
      address{
        font-style:normal;
        @media #{$xssmall-screen} {
          &:first-child{
            margin-bottom: 25px;
          }
        }
      }
      &__title{
        margin:0 0 10px 0;
        padding:0;
      }
      &__name{
        margin:0;
        padding:0;
      }
    }
    &__fulfillment{
      p{
        margin:0;
      }
    }
    &__items{
      p{
        margin:0;
      }
      &__table{
        width:100%;
        th{
          text-align: left;
        }
        tbody{

        }
        tfoot{
          tr:first-child{
            td{
              border-top:1px solid color("gray","lighten-1");
            }
          }
        }
      }
    }
    &__totals{

    }
  }
}