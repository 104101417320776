.quick-access{
  height:40px;
  line-height: 38px;
  text-transform: uppercase;
  @media #{$small-screen} {
    line-height:30px;
  }

  .register {
    display: inline;
    position: relative;
    padding-right: 8px;
    &:hover {
      .register-dropdown {
        display: block;
        @media #{$small-screen} {
          display: none;
        }
      }
    }
  }
  .cart{
    display: inline;
    i{
      vertical-align: text-top;
    }
  }
  .register-dropdown {
    display: none;
    position: absolute;
    z-index: 1000000;
    border-radius: 5px;
    border:1px solid color("gray","lighten-1");
    a {
      color: #fff;
      display: block;
      padding: 7px 25px;
      line-height:25px;
      border-bottom: 1px solid color("gray","lighten-1");
      background-color: #2d2d2d;

      &:first-child{
        border-radius: 5px 5px 0 0;
        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 8px;
          border-left: solid 10px rgba(0,0,0,0);
          border-right: solid 10px rgba(0,0,0,0);
          border-bottom: solid 10px #2d2d2d;
        }
      }
      &:last-child {
        border-radius: 0 0 5px 5px ;
        border-bottom: 0;
      }
      &:visited, &:active {
        color: #fff;
      }
      &:hover{
        background-color: color("carusos-red","lighten-1");
        &:before{
          border-bottom-color: color('carusos-red','lighten-1');
        }
      }
    }
  }

  a{
    text-decoration: none;
    font-size:0.75rem;
    display: inline-block;
    &:first-child:hover + .register-dropdown{
      display: block;
      @media #{$small-screen} {
        display: none;
      }
    }
    &:last-child{
      padding-right: 0;
    }

    .right {
      position: relative;
    }
  }
}

nav{
  svg{
    margin-left: 5px;
    width: 15px;
    .arrow-icon{
      fill:color("shade","white");

    }
  }
  position: relative;
  overflow: visible !important;
  background-color: color("carusos-red","base");
  border-bottom: 1px solid color("carusos-red","lighten-1");
  font-size:1rem;
  text-shadow: 0.5px 0.9px 1px rgba(0, 0, 0, 0.5);
  font-weight: 300;
  width:100%;

  .toggle{
    z-index:10000;
  }


  @media #{$medium-screen} {
    height: 65px;
    line-height: inherit;
  }

  &.fixed{
    position:fixed; top:-10px; left:0;
    z-index: 110;
    @media #{$medium-screen} {
      position: static;
    }
  }

  .cart{
    position: absolute;
    display: none;

    @media #{$medium-screen} {
      display: block;
      top:25px;
      left:50px;
      height: 18px;
      width: 30px;
      position: absolute;
    }

    @media #{$xssmall-screen} {
      top: 25px;
      left: 20px;
    }

    a{
      color: color("shade","white");
    }
  }

  .link-container{
    width: 50%;
    &:first-child{
      float: left;
      ul {
        margin-right: 142px;
      }
    }
    &:last-child{
      float: left;
      > ul {
        margin-left: 142px;
        @media #{$medium-screen} {
          margin-left: 0;
        }
      }
    }
  }

  &:before{
    content: ' ';
    position: absolute;
    top: 7px;
    border-top: 2px solid color("shade","white");
    width: 100%;
    z-index: 100;
  }

  .nav{
    // display: flex;
    // justify-content: center;
    width:100%;

    @media #{$medium-screen} {
      display: none;
    }
  }

  #logo-container{
    height:1px;
    z-index: 100;
    line-height: normal;
    margin-top: -1px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);


    a{
      text-align: center;
      display: block;
    }

    #logo{
      max-width:300px;

      @media #{$small-screen} {
        max-width: 200px;
      }
    }
  }

  ul.nav-items{
    list-style: none;
    padding:0;
    margin:0;
    position: relative;

    &.text-left{
      li:last-child{
        padding:0 0;
      }
    }
    &.text-right{
      li:first-child{
        padding:0 0;
      }
    }

    li{
      display: inline-block;
      position: relative;
      @media #{$medium-screen} {
        border-bottom: solid 1px color("carusos-red","lighten-1");
      }

      &.active{
        > a{
          background: color("carusos-red","lighten-1");
          font-weight:700;
        }
      }

      &.open{
        a + ul{
          display: block;
        }
        > a{
          background: color("carusos-red","lighten-1");
        }
      }

      a{
        display: block;
        color: color("shade","white");
        text-transform: uppercase;
        text-decoration: none;
        padding: 30px 15px 20px;
        @media #{$xmedium-screen} {
          padding: 30px 8px 20px;
          font-size: 14px;
        }
      }
    }

    li.expandable {
      @media #{$medium-screen} {
        > a{
          &:after{
            content:'\f078';
            font-family: FontAwesome;
            position: absolute;
            right:20px;
            color:color("shade","white");

          }
        }
        &.open{
          > a:after{
            content:'\f077';
          }
        }
      }
    }

    ul{
      position: absolute;
      min-width: 224px;
      z-index: 2;
      list-style-type: none;
      margin: 0 0 0 0;
      padding: 0;
      display: none;
      background: color("carusos-red","base");
      border-radius: 0px 0px 5px 5px;

      @media #{$medium-screen} {
        position: relative;
        width: 100%;
        background-color: #4A1617;
      }

      &.shift-left{
        left: -70px;
        @media #{$medium-screen} {
          left: 0px;
        }
      }

      &:hover{
        display:block;
      }

      li{
        display: block;
        text-align: left;
        border-bottom: solid 1px color("carusos-red","lighten-1");
        float: none;

        &:hover, &:active {
          background: color("carusos-red","lighten-1");
        }

        &:first-child{
          border-top:2px solid #901619;
        }

        &:last-child{
          border-bottom: none;
        }

        a{
          padding:10px;
        }
      }
    }

  }

  .shop-link {
    margin-left:25px;
    position: relative;
    @media #{$medium-screen} {
      margin-left: 0px;
    }
    i {
      padding-right: 5px;
    }
    &:after {
      content: '';
      position: absolute;
      width:1px;
      height: 24px;
      background-color: #fff;
      top: 27px;
      left: -12px;
      @media #{$medium-screen} {
        display: none;
        padding-left: 0px !important;
        margin: 0;
      }
    }
  }
}

.nav.expanded{
  position: absolute;
  display: block;
  width:100%;
  background-color: rgba(91, 2, 4, 1);
  z-index:1000;
  min-height: 1400px;
  top: 0px;
  @media #{$xmedium-screen} {
    padding-top: 63px;
  }
  .link-container{
    width:100%;
    display: block;
    margin:0;
    > ul{
      width:100%;
      display: block;
    }
    li,a{
      width:100%;
      text-align: center;
    }
    a {
      padding: 30px 0;
    }
    ul.nav-items{
      text-align: left;
    }
  }
}

#shop-header {
  nav {
    background-color: #2d2d2d;
    border-bottom: solid 1px #000;
    .link-container:last-child ul{
      // margin-left: 117px;
    }

  }
}

.no-touch{
  nav ul.nav-items li:hover{
    a + ul{
      display: block;
      @media #{$medium-screen} {
        display: none;
      }
    }
    > a{
      background: color("carusos-red","lighten-1");
    }
  }
}