.free-shipping{
  $root:&;
  text-decoration: none;
  display: block;
  &__heading{
    font-weight:bold;
    font-size:18px;
    text-transform: uppercase;
  }
  &__icon{
    max-width: 20px;
    vertical-align: text-top;
    margin-right: 3px;
  }

  &--spaced{
    padding: 10px 0;

    @media #{$xssmall-screen} {
      padding: 11px 0;
    }
  }

  &--bordered{
    border-bottom: 1px solid $light-gray;
  }

  &--white{
    color: color("shade","white");
    #{$root}__icon{
      path{
        fill: color("shade","white");
      }
    }
  }
  &--full-width{
    text-align: center;
    width:100%;
    background-color: color("gray", "lighten-2");
    margin:0;
    padding:8px 0;
  }
}

.internal-hero{
  .free-shipping{
    text-align: right;
    padding-right: 15px;
    white-space: nowrap;
    @media #{$small-screen} {
      display: none;
    }
  }
}