.shop{
  .contact{
    &__shop-info{
      padding-bottom: 25px;
      &__address,&__numbers{
        float: left;
        width:50%;
      }
      &__address{
        address:after{
          display: none;
        }
      }
      &__numbers{
        text-align: right;
      }

    }
  }
}