$notification-width:300px;

.notification{
  $root: &;
  position: fixed;
  min-height:50px;
  min-width: $notification-width;
  max-width: $notification-width;
  bottom:30px;
  right:-1*$notification-width;
  animation: slideInOut;
  animation-duration: 7s;
  z-index: 100;
  border:1px solid color("gray","lighten-3");
  display: flex;
  align-items: center;
  background-color: color("gray","darken-4");
  color:color("shade","white");
  padding:10px;
  border-radius: 5px;
  &:before{
    font-family: FontAwesome;
    font-size:24px;
  }

  &__message{
    margin:0 0 0 10px;
  }
  &__heading{
    font-size:24px;
  }


  &--success{
    right: 50%;
    bottom: 50%;
    -webkit-transform:translate(50%, 50%);
    transform: translate(50%, 50%);
    opacity:0;
    background-color: color("carusos-red","base");
    animation:growInOut;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    box-shadow:0 0 80px 0 rgba(0,0,0,0.5);

    @media #{$small-screen} {
      min-width: 90%;
      max-width: 90%;
    }

    &:before{
      font-size:40px;
      content:"\f058"
    }

    #{$root}__heading{
      margin:10px 0;
    }

    #{$root}__message{
      text-align: center;
      margin:0;
    }
  }
  &--error{
    &:before{content:"\f06a"}
  }
  &--warning{
    &:before{content:"\f071"}
  }
}

@keyframes slideInOut {
  0%   {transform: translateX($notification-width)}
  4%  {transform: translateX(-1*$notification-width)}
  96%  {transform: translateX(-1*$notification-width)}
  100% {transform: translateX($notification-width)}
}

@keyframes growInOut {
  0% {
    opacity:0;
  }
  10% {
    opacity:1;
  }
  90%  {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}