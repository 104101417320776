#campaignified-modal {
  input{
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFF;
    font-size: 1rem;
    line-height: 1.4;
    border-radius: 5px;
    font-weight: normal;
    border: 1px solid #e0e0e0;
    transition: border 0.2s;
    color: #717374;

    &:focus{
      outline: 0;
    }
  }
  footer{
    position: static;
    display: flex;
  }
  button{
    display: inline-block;
    &:hover{
      color: $dark-grey;
      border:1px solid $dark-grey;
    }
  }

  .js-close{
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    display: block;
    width: auto;
    color: $white;
    background-color: $white;
    border: $white;

    &:hover{
      color: $white;
      background-color: $white;
      border: $white;
    }
    &:after{
      position: absolute;
      color: $light-gray;
      content: '\f00d';
      font-family: FontAwesome;
      font-size: 24px;
      right:15px;
      top:15px;
    }
  }
}