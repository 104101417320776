/*********************************************************
		3.1.0 TYPOGRAPHY | Font Faces
	**********************************************************/


/*********************************************************
		3.1.1 TYPOGRAPHY | Font Sizes
	**********************************************************/

html{
  font-size:12px;

  @media #{$xlarge-screen}{}
  @media #{$large-screen}{}
  @media #{$xmedium-screen}{}
  @media #{$medium-screen}{font-size:16px;}
  @media #{$small-screen}{font-size:16px;}
  @media #{$xssmall-screen}{font-size:16px;}
}

/*********************************************************
		3.1.2 TYPOGRAPHY | Font Settings
	**********************************************************/

h1,h2,h3,h4,h5,h6,p,li,a{
}
h1{
}
h2{
}
h3{
}
h4{
}
h5{
}
h6{

}
a:visited { text-decoration: none; color:#222222;}
a:hover { text-decoration: none; color:#888888;}
a:focus { text-decoration: none; color:#888888;}
a:hover, a:active { text-decoration: none; color:#888888}

.no-break{ white-space: nowrap;overflow: hidden}

.italic{font-style: italic;}
.bold{font-weight: bold;}
.small{font-size: 0.9rem;}

.text-uppercase{text-transform: uppercase}
.text-lowercase{text-transform: lowercase}
.text-titlecase{text-transform: capitalize}

.text-left{text-align: left}
.text-center{text-align: center}
.text-right{text-align: right}
/*********************************************************
		3.1.3 TYPOGRAPHY | Mixins
	**********************************************************/

/****
 CREDIT TO: https://gist.github.com/jonathantneal
****/

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
