form{
  .input-group{
    input[type="text"],input[type="password"],input[type="search"], select, textarea{
      border-radius: 5px;
      &:focus{
        outline: 0;
      }
    }
    a.btn,button,input[type='submit']{
      padding: 12px;
      font-size:1rem;
    }

  }
  input, button,textarea,select{
    font-family: inherit;
  }

  input[type="search"]{
    & + span{
      position: absolute;
      top: 20px;
      right: 15px;
    }
  }
  input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
  input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  .error {
    .error-message{
      position: absolute;
      right:8px;
      bottom:12px;
      color:red;
      font-size:10px;
    }
  }

  /** Checkboxes and Radio Buttons **/
  input[type="checkbox"], input[type="radio"]{
    opacity: 0;
    position: absolute;
  }

  input[type="checkbox"], input[type="radio"], input[type="checkbox"] + label, input[type="radio"] + label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }

  input[type="checkbox"] + label, input[type="radio"] + label {
    position: relative;
  }

  input[type="checkbox"] + label:before, input[type="radio"] + label:before {
    content: '';
    background: $white;
    border: 1px solid $light-gray;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
  }

  input[type="checkbox"]:checked + label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    background: $carusos-light-red;
    color: $white;
  }

  input[type="radio"] + label:before {
    border-radius: 50%;
  }

  input[type="radio"]:checked + label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #bbb;
  }

  input[type="checkbox"]:focus + label, input[type="radio"]:focus + label {
  }
}