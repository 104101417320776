.category{
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // position: relative;
  // margin-bottom: 1em;
  // overflow: hidden;
  // height: 182px;
  position: relative;
  @media #{$medium-screen} {
    margin-bottom: 20px;
  }

  img{
    display: block;
    width: 100%;
    border-radius: 5px;
  }

  a{
    display: block;
    &:after{
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      background-color: rgba(0,0,0,0.5);
      outline: solid 1px #fff;
      outline-offset: -12px;
      z-index: 1;
      border-radius: 5px;
      @media #{$small-screen} {
        outline-offset: -8px;
      }
    }

    &:hover:after {
      opacity: 0;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
    }
  }

  h2{
    z-index: 100;
    margin:0;
    position: absolute;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 1.75rem;
    font-weight: bold;
    text-transform: uppercase;
    @media #{$xssmall-screen} {
      font-size: 1.2rem;
    }
  }
}
.shop-cta {
  a:after {
    background-color: rgba(91, 2, 4, 0.78);
  }
}

#our-story{
  padding:4em 0;
  @media #{$medium-screen} {
    padding:0;
  }
}

#recent-news{
  padding: 0 0 2em 0;
  @media #{$small-screen} {
    padding:0;
  }

  .post .item{
    padding:0;

    .info{
      font-size: 0.875rem;
      font-style: italic;
      padding:0;
    }
    .image{
      padding-bottom: 10px;
    }
    h3{
      text-transform: none;
      a{
        text-decoration: none;
      }
    }
  }
}
.shop-coffee-link {
  text-align: center;
  background-color: color('carusos-red','lighten-1');
  line-height: 3;
  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    &:visited, &:active {
      color: #fff;
    }
  }
}
