.container-seal{
  margin-bottom: 30px;
  @media #{$medium-screen} {
    padding:0;
  }
}
.internal-seal{
  position: relative;
  /* Seal image */
  .internal-top-border-left {
    background-image: url("/img/internal-top-line.png");
    width: calc(50% - 311px/2);
    height: 35px;
    top: -25px;
    position: absolute;
    border-radius: 10px 0 0 0;
    // box-shadow: 16px -14px 17px rgba(0, 0, 0, 0.52);

    @media #{$small-screen} {
      border-radius: 0;
    }
  }
  .internal-top-border-right {
    background-image: url("/img/internal-top-line.png");
    width: calc(50% - 311px/2);
    height: 35px;
    top: -25px;
    right:0;
    position: absolute;
    border-radius: 0 10px 0 0;
    // box-shadow: 16px -14px 17px rgba(0, 0, 0, 0.52);


    @media #{$small-screen} {
      border-radius: 0;
    }

  }
  .seal {
    position: absolute;
    background-image: url("/img/internal-seal-drop-shadow.png");
    background-repeat: no-repeat;
    left: calc(50% - 311px/2);
    top: -26px;
    height: 124px;
    width: 311px;

    @media #{$small-screen} {
      width:100%;
      background-position: 50%;
      left:0;
      top: -28px;
    }
  }
  /*End of seal image */
}
