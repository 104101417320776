header{
  nav{
    overflow: hidden;
    .toggle{
      display: none;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      @media #{$medium-screen} {
        display: block;
        top:30px;
        right:50px;
        height: 18px;
        width: 30px;
        position: absolute;
      }

      @media #{$xssmall-screen} {
        top:30px;
        right:15px;
      }

      &.expanded{
        transform: rotate(90deg);
        transform-origin: center center;
      }
    }
    .container{
      @media #{$medium-screen} {
        padding:0;
      }
    }
    .mobile-nav{
      display: none;
      @media #{$medium-screen} {
        display: block;
        float: none;
        width: 100%;
        top: 0;

        ul{
          /* Set our transitions up. */
          -webkit-transition: max-height 0.5s;
          -moz-transition: max-height 0.5s;
          transition: max-height 0.5s;

          max-height: 0;
          overflow: hidden;
          padding: 0;
          margin: 0;

          &.expanded{
            display: block;
            max-height: 350px;
          }

          li{

            &:first-child{
              border-top: 1px solid #ddd;
            }
          }
        }

        a {
          width: 100%;
          display: block;
          background: #FFF;
          text-align: center;
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          text-decoration: none;
          font-size: 1.33rem;
          text-transform: uppercase;
        }
      }
    }
    .primary-nav{
      width:100%;
      
      @media #{$medium-screen} {
        display: none;
      }
      
      ul{
        list-style:none;
        position: relative;
        left: 220px;
        font-weight:600;
        font-size:1.08rem;
        margin:2rem 0 0 0;

        @media #{$xmedium-screen} {
          left: 175px;
          font-size:0.8rem;
        }
        @media #{$medium-screen} {
          left:50px;
        }

        li{
          float: left;
          padding:1rem;

          a{
            transition: all 0.2s;
            text-decoration: none;
            padding-bottom:0.7rem;
          }

          &.inactive{
            a{
            }
            &:hover{
              a{
                border:0;
                cursor: default;
              }
            }
          }
          &:hover, &.active{
            a{
            }
          }

        }
      }
    }
  }
}