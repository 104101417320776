.product-grid{
  display: flex;
  flex-wrap: wrap;
}
.product-block{
  padding:0 10px;
  text-align: center;
  min-height:250px;

  @media #{$small-screen} {
    padding:0;
    margin:0;
}

  a{
    text-decoration: none;
    &:focus{
      outline: none;
    }
  }

  .product-image{
    width:100%;
  }

  .product-title{
    font-size:16px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0;
  }

  .product-brand{
    display: block;
    font-size:16px;
  }

  .price-block{
    display: block;
  }
}
