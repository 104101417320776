.one-column-internal-page {
  background-image:url("/img/wholesale-coffee-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  img {
    border-radius: 5px;
    min-height: 1px;
  }

  @media #{$medium-screen}{
    background-size: auto;
  }
}

// page backgrounds

.about-bg {
  background-image: url('/img/carusos-bg1.jpg');
}

.wholesale-bg {
  background-image: url('/img/carusos-bg3.jpg');
}

.services-bg {
  background-image: url('/img/carusos-bg2.jpg');
}

.contact-bg {
  background-image: url('/img/carusos-bg4.jpg');
}

.shop-bg {
  background-image: url('/img/carusos-bg6.jpg');
}

.admin-bg {
  background-image: url('/img/carusos-bg5.jpg');
}

// end page backgrounds

.one-column-internal-content {
  position: relative;
  margin: 0 auto;

  .container{
    background-color: color("shade","white");

    @media #{$medium-screen}{

    }

    .wrapper{
      background-color:#FFF;
      margin: 20px 60px;
      padding: 100px 0 0;
      min-height: 390px;
      @media #{$small-screen} {
        margin:0;
        padding:70px 0;
      }
    }
  }
  .one-column{
    background-color: color("shade","white");
  }

}

.one-column-internal-section {
  padding: 50px 0;
  &:first-child{
    padding-top: 0;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0;
    margin-top: 0;
  }
  img {

  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 10px auto;
    max-width: 800px;
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  a{
    text-decoration: none;
    color: color("carusos-red","base");
  }
}
#mission {
  background: url('/img/carusos-mission-bg.jpg');
  text-align: center;
  border-radius: 5px;

  h2, p {
    color: color("shade","white");
  }
  p {
    font-size: 1.1rem;
  }
}

#history {
  padding-top: 10px;

  h2{
    margin-top: 0;
  }
}

#sourcing {
  h2 {
    margin-top: 0;
  }
}

#contact {
  padding-top: 10px;
  text-align: left;
  @media #{$xssmall-screen} {
    text-align: center;
  }
  address{
    margin-top: 2px;
    line-height: 1.5;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      background-color: #464646;
      width: 1px;
      height: 66px;
      top: -22px;
      left: 89%;

      @media #{$xmedium-screen} {
        display: none;
      }
    }
    @media #{$xssmall-screen} {
      margin-bottom: 20px;
    }
  }
  p {
    position: relative;
    &:after{
      content: '';
      position: absolute;
      background-color: #464646;
      width: 1px;
      height: 66px;
      top: 3px;
      left: 40%;
      @media #{$xlarge-screen-and-up} {
        display: none;
      }
      @media #{$xlarge-screen} {
        display: none;
      }
      @media #{$large-screen} {
        display: none;
      }
      @media #{$xmedium-screen} {
        display: block;
        left: -8%;
      }
      @media #{$medium-screen} {
        display: block;
        left: -30%;
      }
      @media #{$xssmall-screen} {
        display: none;
      }
    }
  }
  span {
    color: color("carusos-red","base");
    font-weight: bold;
  }
  h4, p {
    margin-top: 0;
  }
  h4 {
    font-size: 1.2rem;
  }
}
.contact-map {
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
  // height: 368px;
  img {
    position: relative;
    transform: translate(-50%);
    left: 50%;
  }
}

#wholesale-coffee {
  .btn-lg {
    padding: 0.75em 30px;
    &:first-of-type {
      margin-right: 40px;
    }
    @media #{$small-screen} {
      margin: 0 auto 10px !important;
    }
  }
}
