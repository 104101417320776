html{
  font-family: "Roboto Condensed", "Arial", "sans-serif";
  font-size:16px;
}

a{
  color: color("gray", "darken-2");
  transition: color 0.3s;

  &:visited{
    color: color("gray", "darken-2");
  }

   &:hover, &:focus{
    color: color("gray", "base");
  }

  &.link-lg{
    text-transform: uppercase;
    font-size:1rem;
    padding-top: 15px;
    display: block;
  }
}


.heading{
  font-size: 3rem;
  color: color("carusos-red","base");
  text-transform: uppercase;
  font-weight: bold;
  display: block;
}

.no-break{
  white-space: nowrap;
  overflow: hidden;
}

p.open-text{
  line-height: 2em;
  @media #{$small-screen} {
    line-height: 1.2em;
  }
}

.body-copy{
  p,ul,ol{
    font-family: "garamond", "Georgia", "serif";
    line-height: 1.5rem;
    &:last-child{
      margin-bottom:0;
    }
  }
  .open-text{
    line-height: 1.75em;
  }
}

.wf-loading {
  visibility: hidden;
}

h2{
  font-size:2rem;
  margin:0;
  text-transform: uppercase;
}
h3{
  font-size:1.4rem;
  margin:0 0 0.5em 0;
  text-transform: uppercase;
}
h1,h2,h3,h4,h5,h6,p,ul,ol{
  color: #464646;
}

.rule-line{
  display: block;
  height:1px;
}
