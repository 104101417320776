.product-content{
  width:100%;

  .product-info{
    margin: 1em 0;

    h1,h2{
      margin:0;
      font-size:1.5rem;
      text-transform: uppercase;
    }
  }

  .product-description{
    // border-top: 1px solid color("gray","lighten-1");
    // border-bottom: 1px solid color("gray","lighten-1");
    margin: 1em 0;

    p{
      margin:0;
      padding:1em 0;
    }
  }
  .product-photo-container{
    position: relative;
    max-width: 430px;
    margin: 0 auto;
  }
}

.js--qty-adjuster, .ajaxifyCart--qty-adjuster{
  padding:10px 0;
}

.js-qty{
  margin:0;
}

.single-option-selector {

}


.product-price {
  font-weight: bold;
  font-size: 1.2rem;
  color: color("carusos-red","base");
  line-height: 44px;
}

.product-info {
  h1, h2 {
    color: color("carusos-red","base");
    font-size: 2rem;
  }
}
.product{
  &__image-gallery{
    list-style: none;
    margin:0;
    padding:0;
    text-align: center;
    display: flex;
    justify-content: center;
    &__item{
      display: inline-block;
      margin:0 8px 8px 0;

      &:first-child{
        margin-left:0;
        padding-left: 0;
      }
      &:last-child{
        margin-right:0;
        padding-right: 0;
      }
      
      a{
        border:1px solid color("gray","lighten-3");
        display: block;
      }
    }
  }

  &-image{
    position: relative;
  }

  &__sale-flag{
    position: absolute;
    top:0;
    left:10px;
    border-radius: 0 !important;
  }
}

.sale-tag{
  text-decoration: line-through;
  color:color("gray", "darken-2");
  padding-right: 5px;
}

.product-content{
  .sale-tag{
    padding-right: 10px;
    font-size: 22px;
  }
}