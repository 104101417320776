
form{
  width:100%;

  &.text-center{
    input{
      text-align: center;
    }
  }

  &.text-uppercase{
    input{
      text-transform: uppercase;
    }
  }

  label{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .input-group{
    width:100%;
    padding:0.5rem 0;
    position: relative;

    &.with-icon{
      position: relative;

      .icon{
          position: absolute;
          font-size: 1.25rem;
      }

      &.inside-right{
        .icon{
          top: 1.5rem;
          right:1.5rem;
        }
      }
      &.inside-left{
        text-indent: 30px;

        .icon{
          top: 5px;
        }
      }
    }

    select{
      option{
      }
      option[disabled]{
        background-color: gray;
        color:darkgrey;
      }
    }

    .select-chevron{
      position: absolute;
      right:0;
      top:20px;
      &:after{
        pointer-events:none;
        position: absolute;
        content: '\f078';
        font-family: FontAwesome;
        right:10px;
      }
    }
    
    select::-ms-expand {
      display: none;
    }

    input[type="text"],input[type="password"],input[type="search"], select,textarea,.output-box{
      box-sizing: border-box;
      padding:10px;
      width:100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #FFF;
      font-size:1rem;
      line-height: 1.4;
      border-radius: 0;
      font-weight:normal;
      border: 1px solid color("gray","lighten-2");
      transition: border 0.2s;
      color: #717374;

      &:focus{
          border: 1px solid #66afe9;
          outline: 0;

        &::-webkit-input-placeholder {
          color: transparent;
        }
      }

      &::-webkit-input-placeholder {
        color: #717374;
      }

      &.disabled{
        background-color: color("gray","lighten-2");
        color: color("gray","lighten-1");
      }
    }

    &.error input, &.error select, &.error textarea, select.error {
      border:1px solid red;
      color:red;

      &::-webkit-input-placeholder {
        color:red;
      }
    }

    &.date-time select{
      float:left;

      &.date{
        width:49%;
        margin-right:1%;

        @media #{$xssmall-screen}{
          margin-right:0;
          width:100%;
        }
      }

      &.time{
        width:49%;
        margin-left:1%;

        @media #{$xssmall-screen}{
          margin-top: 1rem;
          margin-left:0;
          width:100%;
        }
      }
    }
  }
}
.input-group{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
