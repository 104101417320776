.lightbox{
  display: none;
}

.featherlight{
    .featherlight-content, .featherlight-iframe{
      position: static;
      -webkit-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
      padding: 25px 25px 0;
    }
    .featherlight-close-icon{
      top:25px;
      right:25px;
      font-size: 2rem;
      color: #FFF;
      background: transparent;
      overflow: visible;
      cursor: pointer;
      width: 44px;
      height: 44px;
      line-height: 44px;
    }

  &:last-of-type{
      background: rgba(11, 11, 11, 0.6);

  }
}