.two-column-left-page {
  background-image:url("/img/wholesale-coffee-bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;

  @media #{$medium-screen}{
    background-size: auto;
  }
}

.blog-bg{
  background-image: url('/img/carusos-bg8.jpg');
}

.two-column-left-content {
  position: relative;
  margin: auto 0;

  .container{
    background-color: color("shade","white");

    @media #{$medium-screen}{
      padding: 0 20px;
    }

    .wrapper{
      margin: 0 0 25px 25px;
      @media #{$small-screen} {
        margin:0;
      }
    }
  }
  .one-column{
    background-color: color("shade","white");
  }

  .left-nav{
    margin: 10px 0 0 0;
    padding: 30px 0 30px 0;
    background-color:color("gray","lighten-4");
    border-radius: 10px;

    @media #{$medium-screen}{
      display: none;
    }
    img{
      display: block;
      width: 100%;
    }


    ul{
      padding: 0;
      margin:0;
      list-style: none;
      ul{
        display: none;
      }

      li{
        &:last-child{
          border-bottom:1px solid color("shade","white");
        }
      }
      a{
        display: block;
        padding: 20px;
        text-decoration: none;
        text-align: center;
        border-top:1px solid color("shade","white");
        transition: none;
        background-color: #C9C9C9;

        &:hover{
          background-color: color("carusos-red","base");
          color: color("shade","white");
        }

        &.active {
          background-color: color("carusos-red","base");
          color: color("shade", "white");
          position: relative;
          &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            top: 0px;
            right: -20px;
            border-left: solid 20px color("carusos-red","base");
            border-top: solid 31px #fff;
            border-bottom: solid 31px #fff;
          }
        }
        &.dropdown{
          position: relative;

          &:after{
            right:15px;
            top: 20px;
            position: absolute;
            content: '\f078';
            color: color("gray","lighten-3");
            font-family: FontAwesome;
          }

          &.active{
            + ul{
              display: block;
              background: linear-gradient(180deg, rgba(0,0,0,0.12) 0%,rgba(125,185,232,0) 2%);
            }
          }

          &:hover{
            background-color: color("carusos-red","base");
            color: color("shade","white");
          }

          + ul {
            a{
              background-color: #EFEFEF;

              &.active {
                background-color: color("shade", "white");
                color: color("carusos-red", "base");
              }

              &:hover{
                background-color: color("gray","darken-3");
                color: color("shade","white");
              }
            }
          }
        }
      }
    }
  }
  .right-content {
    background-color: color("shade","white");
    padding-left: 57px;
    padding-top: 100px;
    padding-right: 32px;
    min-height: 390px;
    img {
      border-radius: 5px;
    }

    @media #{$medium-screen} {
      padding-left: 0;
      padding-right:initial;
    }

    .internal-section{
      padding: 50px 0;
      &:first-child{
        padding-top:0;
      }
    }
  }

  .container-seal{
    @media #{$medium-screen} {
      padding:0;
    }
  }
  .internal-seal{
    position: relative;
    /* Seal image */
    .internal-top-border-left {
      background-image: url("/img/internal-top-line.png");
      width: calc(50% - 311px/2);
      height: 35px;
      top: -25px;
      position: absolute;
      border-radius: 10px 0 0 0;
    }
    .internal-top-border-right {
      background-image: url("/img/internal-top-line.png");
      width: calc(50% - 311px/2);
      height: 35px;
      top: -25px;
      right:0;
      position: absolute;
      border-radius: 0 10px 0 0;

    }
    .seal {
      position: absolute;
      background-image: url("/img/internal-seal-drop-shadow.png");
      background-repeat: no-repeat;
      left: calc(50% - 311px/2);
      top: -26px;
      height: 124px;
      width: 311px;

      @media #{$small-screen} {
        width:100%;
        background-position: 50%;
        left:0;
        top: -28px;
      }
    }
    /*End of seal image */
  }
}

.two-column-left-section {
  color: #464646;
  h2 {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 0;
  }
  img {
  }
  p {
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.full-img {
  overflow: hidden;
  max-height: 300px;
  img{
    width: 100%;
  }
}
#applications span {
  text-transform: uppercase;
  font-size: 20px;
  font-family: "franklin-gothic-urw-cond", "Arial", "sans-serif";
}

// #fair-trade {
//   background-image: url('/img/carusos-fair-trade2.jpg');
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 50px;
//   h2, p, a {
//     color: color("shade","white");
//   }
//   p {
//     font-size: 1.1rem;
//   }
// }
//
// #flavored-coffee {
//   background-image: url('/img/carusos-flavor-coffee.jpg');
//   background-size: cover;
//   background-repeat: no-repeat;
//   padding: 50px;
//   h2, p, a {
//     color: color("shade","white");
//   }
//   p {
//     font-size: 1.1rem;
//   }
// }
