a.btn, button, input[type="submit"] {
  color: color("shade","white");
  background-color: color("carusos-red","lighten-1");
  border:1px solid color("carusos-red","base");
  border-radius: 5px;
  font-weight: normal;
  &:focus{
    outline: 0;
  }

  &:hover{
    background-color: transparent;
    border:1px solid color("shade","white");
  }
  &:visited,&:focus{
    color: color("shade","white");
  }

  &.hover-black:hover{
    background-color: transparent;
    color: color("carusos-red","base");
    border: 1px solid color("shade", "black");
  }

  &.transparent{
    background-color: transparent;
    border: 1px solid color("shade","white");
    color: color("shade","white");

    &.black{
      color: color("carusos-red","base");
      border: 1px solid color("shade", "black");
    }


    &:hover{
      color: color("shade","white");
      background-color: color("carusos-red","lighten-1");
    }
  }
  
  &.inline-block{
    width:auto;
    display: inline-block;
  }

  &.btn-lg{
    padding: 0.75em 5em;
  }

  &.btn--tertiary{
    background-color: transparent;
    border:1px solid #e0e0e0;
    color:#717374
  }
  &.link-button{
    display: inline-block;
    color: #616161;
    background-color: transparent;
    text-transform: none;
    border:none;
    padding: 0;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}
